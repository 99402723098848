<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('fund.biaoc')"
        left-arrow
        @click-left="$router.go(-1)"
      >
      </van-nav-bar>
    </div>
    <div class="maincontent">
      <van-row class="bgBox">
<!--        <div class="title">-->
<!--          <span>{{ $t('fund.vip') }}</span>-->
<!--        </div>-->
<!--        <div class="list-title">-->
<!--          <b>{{ $t('fund.oederde') }}</b>-->
<!--          <b>{{ $t('fund.vipll') }}</b>-->
<!--          <b>{{ $t('fund.qixian') }}</b>-->
<!--        </div>-->
        <div class="list">
          <div class="main" v-for="item in list" :key="item.id">
            <div class="serial-num">
              <b>{{ $t('fund.chanpinn') }}：{{ item.title }}</b>
              <b style="margin-left:10px;margin-right: 10px;">{{ $t('fund.orderno') }}：{{ item.code }}</b>
              <b>{{ $t('fund.touzije') }}：{{ item.bj_money }}</b>
            </div>
            <div class="info">
              <div>
                <span class="percentage">{{ item.fee }}%</span>
              </div>
              <div>
                <span class="time-limit">{{ item.sy_money }}</span>
              </div>
              <div>
                <span v-if='item.fund_type == "1"' class="time-limit">{{ item.days }}{{ $t('fund.tian') }}</span>
                <span v-if='item.fund_type == "2"' class="time-limit">--</span>
              </div>
              <div>
                <van-button v-if='item.status == "0"' style='background-color: green'>{{
                    $t('fund.chiyou')
                  }}</van-button>
                <van-button v-if='item.status == "1"' style='background-color: red'>{{
                    $t('fund.yyistop')
                  }}</van-button>
              </div>
            </div>
            <div class="meg">
              <div>
                <b v-if='item.fund_type == "1"'>{{ $t('fund.profit') }}</b>
                <b v-if='item.fund_type == "2"'>{{ $t('fund.dayprofit') }}</b>
              </div>
              <div>
                <b>{{ $t('fund.zongsouyi') }}</b>
              </div>
              <div>
                <b>{{ $t('fund.qixian') }}</b>
              </div>
              <div>
                <van-button v-if='item.fund_type == "1" && item.status == "0"' style='background-color: #868686'>{{
                    $t('fund.noquchu')
                  }}</van-button>
                <van-button @click="zcdetail(item.id)" v-if='item.fund_type == "2" && item.status == "0"'>{{
                    $t('fund.quchu')
                  }}</van-button>
              </div>
            </div>
            <div class="serial-num" style='margin-bottom: 20px;display: flex;justify-content: space-between'>
              <b>{{ $t('fund.chuangjiantime') }}：{{ item.createtime | dateformat }}</b>
              <b v-if='item.fund_type == "1"'>{{ $t('fund.daoqitime') }}：{{ item.endtime | dateformat }}</b>
            </div>
          </div>
        </div>
      </van-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/home/fund/myfundlist')
      if (data) {
        if (data.code === 200) {
          this.list = data.data.list
        }
      }
    },
    // 查看详情
    todetail(id) {
      this.$router.push('/fund/pool/detail/' + id)
    },
    zcdetail(id) {
      this.$router.push('/fund/investment/zcdetail/' + id)
    }
  }
}
</script>

<style lang="less" scoped>
.maincontent {
  padding: 50px 0 0 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .bgBox {
    width: 100%;
    margin-top: 1rem;
    background-size: 100%;
    .title {
      text-align: left;
      font-size: 1.33333rem;
      color: #212121;
      margin: 0.53333rem 0;
      padding-left: 1.46667rem;
      font-weight: 700;
    }
    .list-title {
      display: flex;
      align-items: center;
      text-align: center;
      margin: 0 0.26667rem 0.53333rem;
      padding-left: 1.33333rem;
      b {
        font-weight: 500;
        color: #b2b2b2;
        flex: 1;
      }
      b:first-child {
        text-align: left;
      }
    }
    .list {
      background-color: #fff;
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;
      .main {
        margin: 0 0.26667rem;
        padding-left: 1.33333rem;
        padding-top: 0.53333rem;
        border-bottom: 0.02667rem solid #dcdcdc;
        .serial-num {
          width: 100%;
          text-align: left;
          margin-top: 0.4rem;
          button {
            width: 4.26667rem;
            height: 1.6rem;
            background-color: #004ea3;
            color: #fff;
            border: none;
            border-radius: 0.26667rem;
            font-size: 0.69333rem;
            white-space: nowrap;
          }
        }
        b {
          font-weight: 500;
          font-size: 0.69333rem;
          color: grey;
        }
        .info {
          display: flex;
          align-items: center;
          margin: 0.4rem 0;
          div:first-child {
            text-align: left;
          }
          div {
            flex: 1;
          }
          .percentage {
            font-size: 1.33333rem;
            color: #ff5400;
          }
          .time-limit {
            font-size: 0.8rem;
          }
          button {
            width: 4.26667rem;
            height: 1.6rem;
            background-color: #004ea3;
            color: #fff;
            border: none;
            border-radius: 0.26667rem;
            font-size: 0.69333rem;
            white-space: nowrap;
          }
        }
        .meg {
          display: flex;
          align-items: center;
          margin-bottom: 0.66667rem;
          div:first-child {
            text-align: left;
          }
          button {
            width: 4.26667rem;
            height: 1.6rem;
            background-color: #004ea3;
            color: #fff;
            border: none;
            border-radius: 0.26667rem;
            font-size: 0.69333rem;
            white-space: nowrap;
          }
          div {
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
